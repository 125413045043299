<template>
  <div>
    <v-card class="mx-auto mt-0 mt-sm-15 mt-md-7 mt-lg-7 mt-xl-7" width="600">

      <v-card-text>
        <h1
            class="promo font-weight-bold text-center text-h5 mb-5"
        >
          A medassure agradece o cadastro!
        </h1>
        <h1 class="promo mt-10 mb-10 text-h6 text-center font-weight-medium" color="#5da6db">
          Em breve alguém da nossa equipe entrará em contato.
        </h1>
        <h1 class="promo font-weight-bold text-center text-h5 mt-5">
          Acompanhe nossas redes sociais!
        </h1>
      </v-card-text>

      <v-row class="d-line justify-space-around mt-6">

        <v-card_action>

          <v-btn top icon x-large color="#5da6db" class="mr-15" @click="sendInsta"><v-icon size="65" >mdi-instagram</v-icon></v-btn>

          <v-btn top icon x-large color="#5da6db" @click="sendFB"><v-icon size="65">mdi-facebook</v-icon></v-btn>

        </v-card_action>
      </v-row>

      <v-row class="d-line justify-center mt-15 ">

        <v-card-action class="mb-5">
          <v-btn class="white--text"  color="#5da6db" @click="retornaSite"> Voltar para o site</v-btn>
        </v-card-action>

      </v-row>


    </v-card>


  </div>
</template>

<script>
export default {
  props: [],
  components: {},

  data() {
    return {};
  },
  watch: {},

  methods: {
    retornaSite() {
      window.location.href = 'https://www.medassure.com.br/';
    },

    sendFB() {
      window.location.href = 'https://www.facebook.com/medassurebrasil';
    },

    sendInsta() {
      window.location.href = 'https://www.instagram.com/medassurebrasil/';
    },
  }
};
</script>

<style scoped>
.corpo {
  font-family: Pragmatica;
  font-stretch: extra-condensed;
  color: #5da6db;
}
</style>

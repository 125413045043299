<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-card class="mx-auto mt-0 mt-sm-7 mt-md-7 mt-lg-7 mt-xl-7" width="600">
      <v-card color="#eff4fb" outlined width="600">
        <div class="mt-4 pt-3 mb-2 ml-2 mr-2 pl-2 pr-2">
          <h1 class="promo font-weight-bold text-center text-h5 pb-2">
            Cadastre-se na Medassure!!
          </h1>
          <p class="corpo text-right text-caption">
            *(Campos Obrigatórios)
          </p>
        </div>
      </v-card>
      <v-divider class="mt-n3"></v-divider>

      <v-card-text class="">
        <!-- Razão Social   -->
        <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required|min:3"
        >
          <v-text-field
              v-model="razaoSocial"
              :error-messages="errors"
              class="mt-0"
              label="Qual sua Razão Social, o nome do seu consultório/clínica?*"
              prepend-icon="mdi-hospital-building"
              required
          ></v-text-field>
        </validation-provider>

        <!-- Endereço Comercial  -->
        <validation-provider
            v-slot="{ errors }"
            name="endComercial"
            rules="required|min:3"
        >
          <v-text-field
              v-model="endComercial"
              :error-messages="errors"
              class="mt-n3"
              label="Qual seu endereço comercial?*"
              prepend-icon="mdi-hospital-marker"
              required
          ></v-text-field>
        </validation-provider>

        <!-- Telefone Comercial  -->
        <validation-provider
            v-slot="{ errors }"
            rules="numeric|required"
            name="phoneNumber"
        >
          <v-text-field
              v-model="telComercial"
              :error-messages="errors"
              class="mt-n3"
              label="Telefone com DDD, sem espaços ou parênteses*"
              prepend-icon="mdi-phone"
              required
          ></v-text-field>
        </validation-provider>

        <!-- Contato  -->
        <v-text-field
            v-model="contato"
            class="mt-n3"
            label="Há um responsável pelo contato? Qual seu nome?"
            prepend-icon="mdi-account-alert"
        ></v-text-field>


        <!-- Responsabilidade -->

        <div class="mt-2 mb-4">
          <validation-provider
              v-slot="{ errors }"
              name="responsabilidade"
              rules="required"
          >
            <div class="d-flex justify-center">
              <v-icon class="mt-n1">mdi-account-question</v-icon>
              <h1 class="font-weight-regular  body-1 ml-2">
                Você possui seguro de responsabilidade civil?*
              </h1>
            </div>

            <section class="d-flex justify-space-around mt-2">
              <v-chip-group
                  v-model="responsabilidade"
                  :error-messages="errors"
                  :mandatory="mandatorio"
                  active-class="info white--text"
                  column
                  required
                  type="info"
                  @change="setMandatorio"
              >
                <v-chip class="mr-5" small value="sim">Sim</v-chip>
                <v-chip class="ml-10" small value="nao">Não</v-chip>
              </v-chip-group>
            </section>
          </validation-provider>
        </div>

        <!-- Seguradoras -->

        <div v-if="responsabilidade === 'sim'" class="mt-n2 mb-4">
          <validation-provider
              v-slot="{ errors }"
              name="seguradora"
              rules="required"
          >
            <div class="d-flex justify-center">
              <v-icon class="mt-n1">mdi-account-question</v-icon>
              <h1 class="font-weight-regular  body-1 ml-2">
                Com quem você contratou seu seguro de responsabilidade civil?*
              </h1>
            </div>

            <section class="d-flex justify-space-around mt-2">
              <v-chip-group
                  v-model="seguradora"
                  :error-messages="errors"
                  :mandatory="mandatorio2"
                  active-class="info white--text"
                  column
                  required
                  type="info"
                  @change="setMandatorio2"
              >
                <v-chip small value="mapfre">Mapfre</v-chip>
                <v-chip small value="porto seguro">Porto Seguro</v-chip>
                <v-chip small value="unimed">Unimed</v-chip>
                <v-chip small value="anadem">Anadem</v-chip>
                <v-chip small value="outros">Outras</v-chip>
              </v-chip-group>
            </section>
          </validation-provider>
        </div>

        <!-- Cirurgias -->

        <validation-provider
            v-slot="{ errors }"
            name="cirurgias"
            rules="required"
        >
          <div class="mt-2 mb-4">
            <div class="d-flex justify-center">
              <v-icon class="mt-n1">mdi-medical-bag</v-icon>
              <h1 class="font-weight-regular  body-1 ml-2">
                Nos últimos 12 meses quantas cirurgias você realizou?*
              </h1>
            </div>

            <section class="d-flex justify-space-around mt-2">
              <v-chip-group
                  v-model="cirurgias"
                  :error-messages="errors"
                  :mandatory="mandatorio"
                  active-class="info white--text"
                  column
                  required
                  type="info"
                  @change="setMandatorio"
              >
                <v-chip small value="menos que 50">menos que 50</v-chip>
                <v-chip small value="50">entre 50 e 100</v-chip>
                <v-chip small value="100">entre 100 e 200</v-chip>
                <v-chip small value="mais que 200">mais que 200</v-chip>
              </v-chip-group>
            </section>
          </div>
        </validation-provider>

        <v-divider class="pb-4"></v-divider>

        <p class="ml-2">
          Você aceita receber mensagens por sms, whatsapp e email, sobre o
          universo da cirurgia plástica?
        </p>

        <div class="mt-n8">
          <v-row>
            <v-col class="d-inline-flex" cols="auto">
              <v-dialog max-width="600" transition="dialog-top-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-checkbox
                      v-model="checkbox"
                      :disabled="invalid"
                      class="ml-1"
                      label="Eu concordo com a"
                      required
                      type="checkbox"
                      value="1"
                      @change="valida"
                  ></v-checkbox>
                  <v-btn
                      botton
                      class="no-uppercase mt-1 mx-1 py-1 px-1"
                      color="#5da6db"
                      text
                      v-bind="attrs"
                      x-large
                      v-on="on"
                  >Política de privacidade
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="#5da6db" dark
                    >Política de privacidade
                    </v-toolbar
                    >
                    <v-card-text>
                      <div class="body-2 pt-4 px-2 pb-0">
                        1.Este aplicativo é mantido e operado por medassure
                        Brasil.<br>
                        2.Os dados coletados são expressamente fornecidos
                        pelo usuário no aceite desta política de dados.<br>
                        3.Estas
                        informações serão utilizadas exclusivamente pela medassure
                        para relacionamento com os clientes, por meio de envio de
                        mensagens SMS, WhatsApp e Newsletter.<br>
                        4.As mensagens
                        conterão informações relevantes ao universo da cirurgia
                        plástica e vantagens a serem oferecidas aos usuários.<br>
                        5.O
                        usuário poderá pedir a qualquer momento a exclusão de seus
                        dados.<br>
                        6.Os dados são armazenados em servidores seguros de
                        internet com todas as medidas padrão de segurança.<br>
                        7.A
                        medassure Brasil realizará o sorteio de uma caneta
                        Montblanc.<br>
                        8.A participação do sorteio é exclusiva para
                        membros da Sociedade Brasileira de Cirurgia Plástica.
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn outlined @click="dialog.value = false">Fechar</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
        <v-divider pb-5></v-divider>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
import {required} from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider,} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Preencha para participar da promoção",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      razaoSocial:"",
      endComercial:"",
      telComercial:"",
      contato:"",
      responsabilidade: null,
      seguradora: "",
      cirurgias: "",
      checkbox: "",
      mandatorio: false,
      mandatorio2: false,
    };
  },
  methods: {
    valida() {
      this.$emit("enviarDados", {
        razaoSocial: this.razaoSocial,
        endComercial: this.endComercial,
        telComercial: this.telComercial,
        contato: this.contato,
        responsabilidade: this.responsabilidade,
        seguradora: this.seguradora,
        cirurgias: this.cirurgias,
      });
      this.$emit("enviarDb");
    },

    setMandatorio() {
      this.mandatorio = !this.mandatorio;
    },

    setMandatorio2() {
      this.mandatorio2 = !this.mandatorio2;
    },
  },
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}
</style>

<template>
  <!--timer-->
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-card class="mx-auto mt-0 mt-sm-15 mt-md-7 mt-lg-7 mt-xl-7" width="600">
      <v-card color="#eff4fb" outlined width="600">
        <div class="mt-4 pt-3 mb-2 ml-2 mr-2 pl-2 pr-2">
          <h1 class="promo font-weight-bold text-center text-h5 pb-2">
            Cadastre-se na Medassure!!
          </h1>
          <p class="corpo text-right text-caption">
            *(Campos Obrigatórios)
          </p>
        </div>
      </v-card>
      <v-divider class="mt-n3"></v-divider>

      <v-card-text class="">

        <!-- Nome     -->
        <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required|min:3|alpha_spaces"
        >
          <v-text-field
              v-model="nome"
              :error-messages="errors"
              class="mt-1"
              label="Nome Completo*"
              prepend-icon="mdi-account-circle"
              required
          ></v-text-field>
        </validation-provider>

        <!-- Título  -->
        <v-text-field
            v-model="titulo"
            class="mt-n1"
            label="Por qual título gostaria de ser tratado?"
            prepend-icon="mdi-badge-account-horizontal"
        ></v-text-field>

        <!-- CRM    -->
        <validation-provider
            v-slot="{ errors }"
            name="crm"
            rules="required|min:3"
        >
          <v-text-field
              v-model="crm"
              :error-messages="errors"
              class="mt-n2"
              label="qual o seu CRM?*"
              prepend-icon="mdi-hospital-box"
              required
          ></v-text-field>
        </validation-provider>

        <!--  SBCP      -->
        <validation-provider
            v-slot="{ errors }"
            name="sbcp"
            rules="required"
        >
          <div class="d-inline-flex">
            <v-icon class="mt-n2 ml-1">mdi-account-question</v-icon>
            <h1 class='d-flex justify-center text-decoration-underline font-weight-medium body-1 ml-2 mt-1'>
              Você é associado(a) à SBCP?*
            </h1>

            <section class='d-flex justify-center ml-15 mt-n1'>
              <v-chip-group
                  v-model="sbcp"
                  :error-messages="errors"
                  :mandatory="mandatorio"
                  active-class="info white--text"
                  required
                  type="info"
                  @change="setMandatorio"
              >
                <v-chip class="mr-5" small value="sim">Sim</v-chip>
                <v-chip class="ml-10" small value="nao">Não</v-chip>
              </v-chip-group>
            </section>
          </div>
        </validation-provider>

        <!--  email -->

        <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="email|required"
        >
          <v-text-field
              v-model="email"
              :error-messages="errors"
              class="mt-n1"
              label="Informe seu email, por favor.*"
              prepend-icon="mdi-email-box"
              required
          ></v-text-field>
        </validation-provider>

        <!-- Instagram -->
        <v-text-field
            v-model="instagram"
            class="mt-n2"
            label="se possui, qual seu instagram?"
            prepend-icon="mdi-instagram"
        ></v-text-field>

        <!-- Celular -->
        <validation-provider
            v-slot="{ errors }"
            :rules="{
              required: true,
              regex: '^\\([1-9]{2}\\) (?:9[1-9])[0-9]{3}\-[0-9]{4}$',
            }"
            name="phoneNumber"
        >
          <v-text-field
              v-model="celular"
              v-mask="'(##) #####-####'"
              :error-messages="errors"
              class="mt-n2"
              label="Celular (xx) xxxxxxxxx*"
              prepend-icon="mdi-cellphone-sound"
              required
          ></v-text-field>
        </validation-provider>

        <!--  WAPP     -->
        <validation-provider
            v-slot="{ errors }"
            name="wpFlag"
            rules="required"
        >
          <div class="d-inline-flex">
            <v-icon class="mt-n2 ml-0">mdi-account-question</v-icon>
            <h1 class='d-flex justify-center text-decoration-underline font-weight-medium body-1 ml-2 mt-1'>
              O celular também é WhatsApp?*
            </h1>

            <section class='d-flex justify-center ml-12 mt-n1'>
              <v-chip-group
                  v-model="wpFlag"
                  :error-messages="errors"
                  :mandatory="mandatorio"
                  active-class="info white--text"
                  required
                  type="info"
                  @change="setMandatorio"
              >
                <v-chip class="mr-5" small value="sim">Sim</v-chip>
                <v-chip class="ml-10" small value="nao">Não</v-chip>
              </v-chip-group>
            </section>
          </div>
        </validation-provider>

        <!--        WhatsApp-->
        <div v-if="wpFlag === 'nao'">
                    <validation-provider
                        v-slot="{ errors }"
                        name="whatsapp"
                        rules="numeric|required"
                    >
                      <v-text-field
                          v-model="whatsapp"
                          :error-messages="errors"
                          class="mb-n2"
                          label="Insira o WhatsApp com DDD, sem espaços ou parênteses*"
                          prepend-icon="mdi-whatsapp"
                          required
                      ></v-text-field>
                    </validation-provider>
          <!-- WAPP REGEX -->
<!--          <validation-provider-->
<!--              v-slot="{ errors }"-->
<!--              :rules="{-->
<!--              required: true,-->
<!--              regex: regFixoOuCel,-->
<!--            }"-->
<!--              name="phoneNumber"-->
<!--          >-->
<!--            <v-text-field-->
<!--                v-model="whatsapp"-->
<!--                :key="fixoOuCel"-->
<!--                v-mask="fixoOuCel"-->
<!--                :error-messages="errors"-->
<!--                class="mt-n2"-->
<!--                label="Whatsapp*"-->
<!--                prepend-icon="mdi-cellphone-sound"-->
<!--                required-->
<!--            ></v-text-field>-->
<!--          </validation-provider>-->
        </div>

        <!--  Botão continuar-->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
              :disabled="invalid"
              block
              class="my-1"
              color="success"
              type="submit"
              @click="valida"
          >Continuar
          </v-btn>
        </v-card-actions>
        <v-divider pb-1></v-divider>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
import {alpha_spaces, email, min, numeric, regex, required,} from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider,} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Preencha para completar o cadastro",
});

extend("email", {
  ...email,
  message: "Preencha, por favor, um email válido",
});
extend("min", {
  ...min,
  message: "Preencha, por favor, um nome válido",
});
extend("regex", {
  ...regex,
  message: "Preencha, por favor, um celular válido",
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Apenas letras e espaços",
});
extend("numeric", {
  ...numeric,
  message: "Apenas números",
});


export default {
  props: ["componentKey"],
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      nome: "",
      titulo: "",
      crm: "",
      sbcp: "",
      email: "",
      instagram: "",
      celular: "",
      wpFlag: "",
      whatsapp: "",
      overlay: false,
      mandatorio: false,
      reg: '',
    };
  },
  // computed: {
  //   fixoOuCel() {
  //     if (this.whatsapp.length < 15) {
  //       return '(##) ####-####';
  //     } else {
  //       return '(##) #####-####';
  //     }
  //   }
  // },

  methods: {
    valida() {
      this.$emit("enviarDados", {
        nome: this.nome,
        titulo: this.titulo,
        crm: this.crm,
        sbcp: this.sbcp,
        email: this.email,
        instagram: this.instagram,
        celular: this.celular,
        whatsapp: this.whatsapp,
      });
      this.$emit("enviarProximo");
    },
    update() {
      setTimeout(() => {
        this.$emit("refresh");
      }, 2500);
    },
    setMandatorio() {
      this.mandatorio = true;
    },

    // regFixoOuCel() {
    //   if (this.whatsapp.length < 15) {
    //     return '^\\([1-9]{2}\\) (?:[2-8])[0-9]{3}-[0-9]{4}$';
    //
    //   } else {
    //     return '^\\([1-9]{2}\\) (?:9[1-9])[0-9]{3}\\-[0-9]{4}$';
    //   }
    // }
  },

};
</script>

<style>
.promo {
  font-family: Pragmatica;
  font-stretch: extra-condensed;
  color: #5da6db;
}

.promo2 {
  font-family: Pragmatica;
  font-stretch: extra-condensed;
  color: #fafafa;
}

.corpo {
  font-family: Pragmatica;
}
</style>

<template>
  <v-app>
    <v-app-bar app color="#5da6db" height="110" proeminent>
      <v-container>
        <v-col col="auto">
          <v-img
              src="./assets/medassure_logo.jpg"
              :class="$vuetify.breakpoint.smAndUp ? 'imgXsLg' : 'imgXs'"
          >
          </v-img>
          <h1 :class="$vuetify.breakpoint.smAndUp ? 'sloganXsLg' : 'sloganXs'">
            Sempre com você
          </h1>
        </v-col>
      </v-container>
    </v-app-bar>

    <v-main>
      <component
          :is="navega"
          @enviarDados="receberDados"
          @enviarProximo="proximo"
          @enviarDb="enviarFirestore"
          :key="componentKey"
          @refresh="forceRerender"
      ></component>

      <v-dialog v-model="dialog" hide-overlay persistent width="600">
        <v-card color="#5da6db" dark>
          <v-card-text>
            <h1 class="subtitle-1">
              Agradecemos! Você receberá um em-mail de confirmação e em breve alguém da nossa equipe entrará em contato.!
            </h1>
            <v-progress-linear
                class="mb-0"
                color="black"
                indeterminate
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>

    <v-footer app color="#5da6db" height="10" proeminent>
    </v-footer>
  </v-app>
</template>

<script>
import FormGate from "./components/FormGate";
import FormQuestion from "./components/FormQuestion";
import FormThanks from "./components/FormThanks";
import { db } from "./firebase/firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";

export default {
  name: "App",

  components: {
    FormGate,
    FormQuestion,
    FormThanks,
  },

  data: () => ({
    um: 1,
    componentKey: 0,
    dialog: false,
    numPagina: 1,
    // numeros: [],
    cadastro: {
      nome: "",
      titulo: "",
      crm: "",
      sbcp: "",
      email: "",
      instagram: "",
      celular: "",
      whatsapp: "",
      razaoSocial:"",
      endComercial:"",
      telComercial:"",
      contato:"",
      responsabilidade: null,
      seguradora: "",
      cirurgias: "",
    },
    childForms: ["FormGate", "FormQuestion", "FormThanks"]
  }),
  methods: {
    proximo() {
      this.numPagina++;
    },

    receberDados(dados) {
      Object.assign(this.cadastro, dados);
    },

   async enviarFirestore() {
      this.dialog = true;
      this.addData();
      await addDoc(collection(db, "cadastrados"), this.cadastro);
      this.enviando();
    },

    addData() {
      this.cadastro.data = Timestamp.now();
    },
    // Math.floor(Math.random() * (max - min + 1)) + min;
    // addNum() {
    //   let number = 0;
    //   number = Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000;
    //   if (!this.numeros.includes(number)) {
    //     this.cliente.Num_Sorteio = number;
    //     this.numeros.push(number);
    //   } else {
    //     this.addNum();
    //   }
    // },

    enviando() {
      // this.geraNumero();
      Object.keys(this.cadastro).forEach(key => (this.cadastro[key] = null));
      setTimeout(() => {
        this.dialog = false;
        this.proximo();
      }, 1000);
    },

    forceRerender() {
      this.componentKey += 1;
      window.close();
    },

    // geraNumero() {
    //   this.numeroSorte = this.cliente.Num_Sorteio;
    // },
  },
  computed: {
    navega() {
      return this.childForms[this.numPagina - 1];
    }
  }
};
</script>
<style>
@font-face {
  font-family: Warownia;
  src: local("Warownia"),
  url('./fonts/Warownia/WarowniaBlk.woff') format('woff');
}
@font-face {
  font-family: WarowniaR;
  src: local("WarowniaR"),
  url('./fonts/Warownia Reg/Warownia.woff') format('woff');
}
@font-face {
  font-family: Pragmatica;
  src: local("Pragmatica"),
  url('./fonts/Pragmatica-ExtraLight.woff')format('woff');
}
.taglineXs {
  font-family: WarowniaR;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  margin-top: -8px;
  color: #fafafa;
}
.taglineXsLg {
  font-family: WarowniaR;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin-top: -8px;
  color: #fafafa;
}
.sloganXs {
  font-family: WarowniaR;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
  color: #fafafa;
}
.sloganXsLg {
  font-family: WarowniaR;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
  color: #fafafa;
}
.imgXsLg {
  margin: auto;
  width: 268px;
  height: 51px;
}

.imgXs {
  margin: auto;
  width: 210px;
  height: 40px;
}
</style>


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"mx-auto mt-0 mt-sm-15 mt-md-7 mt-lg-7 mt-xl-7",attrs:{"width":"600"}},[_c('v-card',{attrs:{"color":"#eff4fb","outlined":"","width":"600"}},[_c('div',{staticClass:"mt-4 pt-3 mb-2 ml-2 mr-2 pl-2 pr-2"},[_c('h1',{staticClass:"promo font-weight-bold text-center text-h5 pb-2"},[_vm._v(" Cadastre-se na Medassure!! ")]),_c('p',{staticClass:"corpo text-right text-caption"},[_vm._v(" *(Campos Obrigatórios) ")])])]),_c('v-divider',{staticClass:"mt-n3"}),_c('v-card-text',{},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:3|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-1",attrs:{"error-messages":errors,"label":"Nome Completo*","prepend-icon":"mdi-account-circle","required":""},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})]}}],null,true)}),_c('v-text-field',{staticClass:"mt-n1",attrs:{"label":"Por qual título gostaria de ser tratado?","prepend-icon":"mdi-badge-account-horizontal"},model:{value:(_vm.titulo),callback:function ($$v) {_vm.titulo=$$v},expression:"titulo"}}),_c('validation-provider',{attrs:{"name":"crm","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-n2",attrs:{"error-messages":errors,"label":"qual o seu CRM?*","prepend-icon":"mdi-hospital-box","required":""},model:{value:(_vm.crm),callback:function ($$v) {_vm.crm=$$v},expression:"crm"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"sbcp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-inline-flex"},[_c('v-icon',{staticClass:"mt-n2 ml-1"},[_vm._v("mdi-account-question")]),_c('h1',{staticClass:"d-flex justify-center text-decoration-underline font-weight-medium body-1 ml-2 mt-1"},[_vm._v(" Você é associado(a) à SBCP?* ")]),_c('section',{staticClass:"d-flex justify-center ml-15 mt-n1"},[_c('v-chip-group',{attrs:{"error-messages":errors,"mandatory":_vm.mandatorio,"active-class":"info white--text","required":"","type":"info"},on:{"change":_vm.setMandatorio},model:{value:(_vm.sbcp),callback:function ($$v) {_vm.sbcp=$$v},expression:"sbcp"}},[_c('v-chip',{staticClass:"mr-5",attrs:{"small":"","value":"sim"}},[_vm._v("Sim")]),_c('v-chip',{staticClass:"ml-10",attrs:{"small":"","value":"nao"}},[_vm._v("Não")])],1)],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-n1",attrs:{"error-messages":errors,"label":"Informe seu email, por favor.*","prepend-icon":"mdi-email-box","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('v-text-field',{staticClass:"mt-n2",attrs:{"label":"se possui, qual seu instagram?","prepend-icon":"mdi-instagram"},model:{value:(_vm.instagram),callback:function ($$v) {_vm.instagram=$$v},expression:"instagram"}}),_c('validation-provider',{attrs:{"rules":{
              required: true,
              regex: '^\\([1-9]{2}\\) (?:9[1-9])[0-9]{3}\-[0-9]{4}$',
            },"name":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],staticClass:"mt-n2",attrs:{"error-messages":errors,"label":"Celular (xx) xxxxxxxxx*","prepend-icon":"mdi-cellphone-sound","required":""},model:{value:(_vm.celular),callback:function ($$v) {_vm.celular=$$v},expression:"celular"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"wpFlag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"d-inline-flex"},[_c('v-icon',{staticClass:"mt-n2 ml-0"},[_vm._v("mdi-account-question")]),_c('h1',{staticClass:"d-flex justify-center text-decoration-underline font-weight-medium body-1 ml-2 mt-1"},[_vm._v(" O celular também é WhatsApp?* ")]),_c('section',{staticClass:"d-flex justify-center ml-12 mt-n1"},[_c('v-chip-group',{attrs:{"error-messages":errors,"mandatory":_vm.mandatorio,"active-class":"info white--text","required":"","type":"info"},on:{"change":_vm.setMandatorio},model:{value:(_vm.wpFlag),callback:function ($$v) {_vm.wpFlag=$$v},expression:"wpFlag"}},[_c('v-chip',{staticClass:"mr-5",attrs:{"small":"","value":"sim"}},[_vm._v("Sim")]),_c('v-chip',{staticClass:"ml-10",attrs:{"small":"","value":"nao"}},[_vm._v("Não")])],1)],1)],1)]}}],null,true)}),(_vm.wpFlag === 'nao')?_c('div',[_c('validation-provider',{attrs:{"name":"whatsapp","rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n2",attrs:{"error-messages":errors,"label":"Insira o WhatsApp com DDD, sem espaços ou parênteses*","prepend-icon":"mdi-whatsapp","required":""},model:{value:(_vm.whatsapp),callback:function ($$v) {_vm.whatsapp=$$v},expression:"whatsapp"}})]}}],null,true)})],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"my-1",attrs:{"disabled":invalid,"block":"","color":"success","type":"submit"},on:{"click":_vm.valida}},[_vm._v("Continuar ")])],1),_c('v-divider',{attrs:{"pb-1":""}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import * as VeeValidate from "vee-validate";
import VueMask from "v-mask";
import Vuetify from "vuetify";
Vue.use(VueMask);
Vue.use(Vuetify);
Vue.use(VeeValidate, { inject: false });

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: (h) => h(App)
}).$mount("#app");